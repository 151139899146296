import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./clan2.css";

import avatar from "../../Images/degen.gif";
import distribution from "../../Images/distributionNft.png" 

const Clan2 = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);


  return (
    <div className="C2Super">
      <div className="C2Container">
        <div className="C2Title">
          <div
            data-aos="fade-right"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <h2>SECURITY</h2>
          </div>
          <div
            data-aos="fade-left"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
          </div>
        </div>
        <div className="C2SubContainer">
          <div
            className="C2Text"
            data-aos="fade"
            data-aos-offset="200"
            data-aos-duration="2000"
            data-aos-easing="ease-in-out"
          >
            <p>
           Launched on pump.fun
            </p>
          </div>
          <div
            className="C2Image"
            data-aos="fade"
            data-aos-easing="ease-in-out"
            data-aos-delay="100"
          >
            <img src={avatar} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clan2;
